// @flow
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRedirectPuzzle } from './hooks/puzzleHooks';

import type { TDispatch } from '../../store/store';

import { answers } from '../../puzzles/gtb_puzzles';
import { goToDay } from '../../clientUtils';
import {
    lastPlayedKey,
    lastShownInterAdKey,
    minimumTimeBetweenInterAd,
    numGamesBeforeInterAd,
    PUZZLE_TYPES,
    timeToInvalidateGamesPlayed,
} from '../../constants';
import { isAnswerCorrect, calculatePuzzleDate } from '../../globals';
import { setPreviousPuzzles, setStats } from '../../store/slices/statSlice';
import AutoComplete from '../AutoComplete';
import ImageSelectorButtons from '../ImageSelectorButtons';

import {
    calcStats,
    loadDateFromLocalStorage,
    loadGamesPlayedCount,
    loadLocalStorageItem,
    saveDateToLocalStorage,
    saveGamesPlayedCount,
    saveLocalStorageItem,
    loadMustRedirect,
} from '../../localStateUtils';
import { calcPreviousPuzzleResults } from '../../prevPuzzleUtils';
import { populateGameList } from '../../puzzleUtils';
import { autocompleteSearchThunk, submitGuessThunk } from '../../store/slices/puzzleSlice';
import { debounce } from 'lodash';
import { useSettings } from '../../settings/useSettings';
import type { State } from '../../store/types';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import {
    getInitialGameState,
    getInitialGuesses,
    getInitialGuessList,
    idxToLocalstateNameMapping,
    shakeInput,
} from './PuzzleUtils';
import Footer from '../Footer';
import { setPuzzleType, setShowInterAd } from '../../store/slices/uxSlice';
import { Helmet } from 'react-helmet';
import ShareResult from '../ShareResult';
import ShowGuesses from '../ShowGuesses';
import KofiButton from '../Kofi';
import NavigateButton from '../NavigateButton';
import { handleCustomPuzzleLogic } from '../../easterEgg';
import FinalAnswer from '../FinalAnswer';
import FinalMessage from '../FinalMessage';
import SentenceComponent from '../SentenceComponent';
import Hint from '../Hint';

const GTBPuzzle = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();
    const puzzleType = PUZZLE_TYPES.GTB;

    let { puzzleId } = useParams();
    console.log('puzzleId', puzzleId);
    puzzleId = puzzleId ?? settings.num_days_from_start_date().toString();
    console.log('Num of days from puzzle start date: ', settings.num_days_from_start_date().toString());

    useHotkeys('ArrowRight', () => goToDay(Number(puzzleId) + 1, 'puzzle', settings), [puzzleId]);
    useHotkeys('ArrowLeft', () => goToDay(Number(puzzleId) - 1, 'puzzle', settings), [puzzleId]);

    const { t } = useTranslation();

    const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
    if (savedState == null) {
        // Populate new state
        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'playing');
    }

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guessNum, setGuessNum] = useState(() => getInitialGuesses(savedState, puzzleId, settings));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState, puzzleId, settings));

    const [selectedImage, selectImage] = useState(1);
    const [guessInputValue, setGuessInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [previousGuesses, setPreviousGuesses] = useState(Object.create(null));
    const [ACloading, setACloading] = useState(false);

    const userState = useSelector((state: State) => state.user);

    const stateGuesses = useSelector((state: State) => state.stats[puzzleType].guesses);
    const numWinsAtGuess = useSelector((state: State) => state.stats[puzzleType].numWinsAtGuess);
    useEffect(() => {
        const savedState = loadLocalStorageItem(puzzleId, 'gamestate');
        setGameState(getInitialGameState(savedState));
        setGuessNum(getInitialGuesses(savedState, puzzleId, settings));
        setGuessList(getInitialGuessList(savedState, puzzleId, settings));
    }, [stateGuesses, puzzleId, settings]);

    useRedirectPuzzle(guessNum);

    useEffect(() => handleCustomPuzzleLogic(puzzleType, gameState, puzzleId));
    useEffect(() => {
        dispatch(setPuzzleType(puzzleType));
    });

    const answer = answers[puzzleId].answers;
    const game_content = answers[puzzleId].content;
    const submitted_by = answers[puzzleId].submitted_by;

    const debouncedSave = useMemo(
        (userInput, previousGuesses) =>
            debounce((userInput, previousGuesses) => {
                const delayTimer = setTimeout(() => {
                    const unLinked = populateGameList(answers).filter(
                        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
                    );
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                    setACloading(false);
                }, 3000);

                dispatch(
                    autocompleteSearchThunk({
                        userInput: userInput,
                        puzzleId: puzzleId,
                        itemType: settings.guess_item.toLowerCase(),
                        puzzleType: puzzleType,
                    }),
                )
                    .unwrap()
                    .then((response) => {
                        clearTimeout(delayTimer); // Cancel the timeout
                        const unLinked = response.map((result) => result.title);
                        setFilteredSuggestions(unLinked);
                        setActiveSuggestionIndex(0);
                        setShowSuggestions(true);

                        // store unlinked in previous guesses
                        setPreviousGuesses({
                            ...previousGuesses,
                            [userInput]: { suggestions: unLinked, metadata: response },
                        });
                        setACloading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching autocomplete results:', error);
                        setACloading(false);
                    });
            }, 290),
        [dispatch, puzzleId, settings, puzzleType],
    );

    const changeImage = (newImageNum: number) => {
        if (guessNum < newImageNum - 1 && gameState === 'playing') return;
        selectImage(newImageNum);
        //scroll back to the top. really helps in gtb.
        window.scrollTo(0, 0);
    };

    const skipGuess = () => {
        submitGuess(null, true);
        //scroll back to the top. really helps in gtb.
        window.scrollTo(0, 0);
    };

    const submitGuess = (e: null, isSkipGuess: boolean) => {
        const mustRedirect = loadMustRedirect();
        if (mustRedirect) {
            // Game cannot proceed even if users remove the redirect modal manually, uBlock etc
            return;
        }

        let currentInputValue;

        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        let guessNum = 0;
        const numWinsAtGuessArr: Array<number> = [
            numWinsAtGuess['1'],
            numWinsAtGuess['2'],
            numWinsAtGuess['3'],
            numWinsAtGuess['4'],
            numWinsAtGuess['5'],
            numWinsAtGuess['6'],
        ];
        let newNumWinsAtGuess = [...numWinsAtGuessArr];
        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);
                saveLocalStorageItem(puzzleId, `guess${i + 1}`, currentInputValue);
                guessNum = i + 1;
                setGuessNum(i + 1);
                if (isAnswerCorrect(currentInputValue, answer)) {
                    setGameState('win');
                    saveLocalStorageItem(puzzleId, 'gamestate', 'win');

                    newNumWinsAtGuess[i] += 1;
                    localStorage.setItem(idxToLocalstateNameMapping[i], newNumWinsAtGuess[i].toString());
                    // Interstitial ad - win-scenario
                    if (!userState.user) {
                        const now = new Date();
                        const lastShownInterAd = loadDateFromLocalStorage(lastShownInterAdKey);
                        // $FlowIgnore
                        if (lastShownInterAd && now - lastShownInterAd <= minimumTimeBetweenInterAd) {
                            console.log('Inter-ad: Already shown ad. Wait for the next ad window');
                        } else {
                            const lastPlayed = loadDateFromLocalStorage(lastPlayedKey);
                            // $FlowIgnore
                            if (lastPlayed == null || now - lastPlayed > timeToInvalidateGamesPlayed) {
                                saveGamesPlayedCount(1);
                                console.log(
                                    'Inter-ad: Reset games played, but start at 1 to account for current finished game',
                                );
                            } else {
                                const gamesPlayed = loadGamesPlayedCount();
                                if (gamesPlayed >= numGamesBeforeInterAd - 1) {
                                    // We're in the last game before showing interstitial ad
                                    console.log('Inter-ad: showInterstitialAd and reset games played to 0');
                                    dispatch(setShowInterAd(true));
                                    saveGamesPlayedCount(0); // reset games played
                                    saveDateToLocalStorage(lastShownInterAdKey); // Record the time the inter-ad is shown
                                } else {
                                    console.log('Inter-ad: increment games played');
                                    saveGamesPlayedCount(gamesPlayed + 1);
                                }
                            }
                        }
                        saveDateToLocalStorage(lastPlayedKey);
                    }
                } else {
                    // If we're on guess 1-5, then shake and go to the next image
                    if (i < guessList.length - 1) {
                        shakeInput();
                        selectImage(i + 2);
                    } else {
                        // If we're on guess 6, then they lost
                        setGameState('lose');
                        saveLocalStorageItem(puzzleId, 'gamestate', 'lose');
                        // Interstitial ad - lost-scenario
                        if (!userState.user) {
                            const now = new Date();
                            const lastPlayed = loadDateFromLocalStorage(lastPlayedKey);
                            // $FlowIgnore
                            if (lastPlayed === null || now - lastPlayed > timeToInvalidateGamesPlayed) {
                                saveGamesPlayedCount(1);
                                console.log(
                                    'Inter-ad: Reset games played, but start at 1 to account for current finished game',
                                );
                            } else {
                                const gamesPlayed = loadGamesPlayedCount();
                                console.log('Inter-ad: increment games played');
                                saveGamesPlayedCount(gamesPlayed + 1);
                            }
                            saveDateToLocalStorage(lastPlayedKey);
                        }
                    }
                }
                break;
            }
        }
        const initialStats = calcStats(settings);
        dispatch(
            setStats({
                won: initialStats.totalWon,
                played: initialStats.totalPlayed,
                currentStreak: initialStats.currentStreak,
                maxStreak: initialStats.maxStreak,
                puzzleType: puzzleType,
            }),
        );
        dispatch(
            setPreviousPuzzles({
                previousPuzzles: calcPreviousPuzzleResults(settings).previousPuzzles,
                puzzleType: puzzleType,
            }),
        );

        setGuessInputValue('');

        dispatch(
            submitGuessThunk({
                puzzleId: parseInt(puzzleId),
                guessNum: guessNum,
                guess: currentInputValue,
                puzzleType: puzzleType,
            }),
        );
    };

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        setGuessInputValue(userInput);
        if (userInput.length <= 2) {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            return;
        }
        // check if userInput is in previous guesses
        if (previousGuesses[userInput]) {
            debouncedSave.cancel();
            console.log('already searched this');
            setFilteredSuggestions(previousGuesses[userInput].suggestions);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
            setACloading(false);
            return;
        }
        setACloading(true);
        debouncedSave(userInput, previousGuesses);
    };

    const onKeyDown = (key: KeyboardEvent) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            if (filteredSuggestions.length > 0) {
                setGuessInputValue(filteredSuggestions[activeSuggestionIndex]);
            }
            setShowSuggestions(false);
        }
    };

    const onClick = (e: Event) => {
        setFilteredSuggestions([]);
        // $FlowIgnore target has innertext
        setGuessInputValue(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    const currentPuzzleUrl = settings.game_url(puzzleId);
    const description = 'Try to ' + settings.website_title + '? Daily Puzzle #' + puzzleId;
    const puzzleTitle = settings.website_title + ' Daily Puzzle #' + puzzleId;
    const puzzleDate = calculatePuzzleDate(puzzleId, settings);
    const showPuzzleDate = puzzleDate !== new Date().toDateString();

    return (
        <div className="current-game">
            <Helmet>
                <title>
                    {settings.website_title} #{puzzleId}
                </title>

                <link rel="canonical" href={currentPuzzleUrl} />
                <meta property="og:url" content={currentPuzzleUrl} />
                <meta property="twitter:url" content={currentPuzzleUrl} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />

                <meta property="og:title" content={puzzleTitle} />
                <meta property="twitter:title" content={puzzleTitle} />

                <meta property="og:type" content="website" />

                <meta property="og:site_name" content={settings.website_url} />

                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.guess_item} #{puzzleId}
                <br />
                {showPuzzleDate && puzzleDate}
            </div>
            <div className="Screenshots">
                <div
                    className="book-image-area"
                    style={{
                        maxWidth: '512px',
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        minHeight: '280px',
                    }}
                >
                    {[1, 2, 3, 4, 5, 6].map((imageIndex) => (
                        <div
                            key={imageIndex}
                            className="game-image"
                            style={{
                                display: selectedImage === imageIndex ? 'block' : 'none',
                            }}
                        >
                            {selectedImage === imageIndex && (
                                <Hint
                                    hintText={settings.get_hint_text(imageIndex, answers[puzzleId], settings)}
                                    customStyle={{ position: 'relative', maxWidth: 'max-content' }}
                                />
                            )}
                            <SentenceComponent
                                sentence={answers[puzzleId][`sentence_${imageIndex}`]}
                            ></SentenceComponent>
                        </div>
                    ))}
                </div>
                <div></div>
                <div className="image-selector">
                    <ImageSelectorButtons
                        selectedImage={selectedImage}
                        changeImage={changeImage}
                        gameState={gameState}
                        guesses={guessNum}
                        franchiseMatch={Array(settings.max_guesses).fill(false)}
                    />
                    {gameState === 'playing' && (
                        <button type="button" className="mainButton iconButton skipButton" onClick={skipGuess}>
                            Skip <span aria-hidden="true" className="icon-forward3"></span>
                        </button>
                    )}
                </div>
            </div>

            {gameState !== 'playing' && (
                <div className="result">
                    <FinalMessage gameState={gameState} />
                    <FinalAnswer answer={answer} />

                    <ShareResult
                        guesses={guessNum}
                        gameState={gameState}
                        puzzleId={puzzleId}
                        franchiseMatch={[false, false, false, false, false, false]}
                        maxGuesses={settings.max_guesses}
                    />

                    <ShowGuesses guessList={guessList} answer={answer} puzzleId={puzzleId} />
                    <KofiButton preset="kufi-center" />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <NavigateButton
                            text={t('Play Previous Days')}
                            path={settings.paths.previous_games}
                            hash={puzzleId}
                        />
                        <NavigateButton
                            text={t('See Puzzle Stats')}
                            path={settings.paths.stats.replace(':puzzleId', puzzleId)}
                        />
                    </div>
                    <div className={'game-info'}>
                        <p className="game_content">{game_content}</p>
                        <br />
                        {submitted_by && (
                            <p className={'submitted-by'}>
                                {t('Submitted By')}: <a href={submitted_by}>{submitted_by}</a>
                            </p>
                        )}
                    </div>
                </div>
            )}

            {gameState !== 'win' && gameState !== 'lose' && guessNum < settings.max_guesses - 1 && (
                <p className="guesses-remaining">
                    {settings.max_guesses - guessNum} {settings.max_guesses - guessNum === 1 ? 'guess' : 'guesses'}{' '}
                    {t('remaining')}!
                </p>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum >= settings.max_guesses - 1 && (
                <p className="guesses-remaining">{t('Last guess')}!</p>
            )}

            {gameState === 'playing' && (
                <div className="PlayArea">
                    {gameState === 'playing' && (
                        <div className="input-area">
                            <AutoComplete
                                onChangeFn={onChange}
                                onKeyDownFn={onKeyDown}
                                onClickFn={onClick}
                                activeSuggestionIndex={activeSuggestionIndex}
                                filteredSuggestionsList={filteredSuggestions}
                                inputValue={guessInputValue}
                                shouldShowSuggestions={showSuggestions}
                                loading={ACloading}
                            />
                        </div>
                    )}
                    <button type="button" className="mainButton submitButton" onClick={submitGuess}>
                        Submit
                    </button>
                    {guessList.map((guess, idx) =>
                        guess === '' ? null : (
                            <div key={'gl-' + idx} className="guess-result">
                                {isAnswerCorrect(guess, answer) && '✅'}
                                {!isAnswerCorrect(guess, answer) && '❌'}
                                {guess}
                            </div>
                        ),
                    )}
                </div>
            )}
            <Footer puzzleId={puzzleId} currentPage={'puzzle'} puzzleType={puzzleType} />
        </div>
    );
};

export default GTBPuzzle;
