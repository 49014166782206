// @flow

import { $NotNull } from './globals';
import type { PUZZLE_TYPES_TYPE } from './constants';
import { PUZZLE_TYPES } from './constants';
import { Fireworks } from 'fireworks-js';
import { doc } from 'prettier';

const konami = require('konami');

const showEgge = () => {
    const egge = document.querySelector('#easter-egg');
    if (egge) {
        egge.style.display = 'block';
    }
};
const hideEgge = () => {
    const egge = document.querySelector('#easter-egg');
    if (egge) {
        egge.style.display = 'none';
    }
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

new konami(async () => {
    showEgge();
    await delay(2200);
    hideEgge();
});

const startFireworks = () => {
    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.transition = 'opacity 1s ease-out'; // Add transition for smooth fade
    container.style.opacity = '1';
    // container.style.pointerEvents = 'none';
    document.body.appendChild(container);

    const fireworks = new Fireworks(container, {
        autoresize: true,
        opacity: 0.5,
        acceleration: 1.02,
        friction: 0.97,
        gravity: 1.5,
        particles: 50,
        traceLength: 3,
        traceSpeed: 10,
        explosion: 5,
        intensity: 30,
        flickering: 50,
        lineStyle: 'round',
        hue: {
            min: 0,
            max: 345,
        },
        delay: {
            min: 30,
            max: 60,
        },
        rocketsPoint: {
            min: 50,
            max: 50,
        },
        lineWidth: {
            explosion: {
                min: 1,
                max: 4,
            },
            trace: {
                min: 0.1,
                max: 1,
            },
        },
        brightness: {
            min: 50,
            max: 80,
        },
        decay: {
            min: 0.015,
            max: 0.03,
        },
        mouse: {
            // click: false,
            move: true,
            // max: 1,
        },
    });

    fireworks.start();
    // Create an overlay just for click handling
    // const clickOverlay = document.createElement('div');
    // clickOverlay.style.position = 'fixed';
    // clickOverlay.style.top = '0';
    // clickOverlay.style.left = '0';
    // clickOverlay.style.width = '100%';
    // clickOverlay.style.height = '100%';
    // clickOverlay.style.background = 'transparent';
    // clickOverlay.style.zIndex = '999'; // Place below other UI elements
    // document.body.appendChild(clickOverlay);
    
    // // Add click handler to stop fireworks
    // clickOverlay.addEventListener('click', () => {
    //     fireworks.stop();
    //     document.body.removeChild(container);
    //     document.body.removeChild(clickOverlay);
    // });

    document.body.addEventListener('click', () => {
        fireworks.stop();
        document.body.removeChild(container);
    })

    setTimeout(() => {
        fireworks.stop();
        document.body.removeChild(container);
    }, 18000); // Fireworks duration
};

const showCelebrationMessage = () => {
    const messageContainer = document.createElement('div');
    messageContainer.style.position = 'fixed';
    messageContainer.style.bottom = '40%'; // Position 10% up from the bottom
    messageContainer.style.width = '100%';
    messageContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
    messageContainer.style.color = 'white';
    messageContainer.style.textAlign = 'center';
    messageContainer.style.padding = '20px'; // Increased padding for better appearance
    messageContainer.style.zIndex = '1000';
    messageContainer.style.fontSize = '24px'; // Larger text size
    messageContainer.innerHTML = 'Thanks for playing 1000 days of Guess The Game. Hope to have a 1000 more!';
    messageContainer.style.transition = 'opacity 1s ease-out'; // Add transition for smooth fade
    messageContainer.style.opacity = '1';
    document.body.appendChild(messageContainer);

    document.body.addEventListener('click', () => {
        document.body.removeChild(messageContainer);
    })

    setTimeout(() => {
        document.body.removeChild(messageContainer);
        
    }, 18000); // Message duration
};

const handleCustomPuzzleLogic = (puzzleType: PUZZLE_TYPES_TYPE, gameState: string, puzzle_num: string) => {
    if (puzzleType === PUZZLE_TYPES.GTG) {
        if (puzzle_num !== '1000') {
            return;
        }
        if (gameState === 'playing') {
            return;
        }
        console.log("It's time to party !");
        startFireworks();
        showCelebrationMessage();
    }
    if (puzzleType === PUZZLE_TYPES.GTG) {
        if (puzzle_num === '471') {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://fonts.cdnfonts.com/css/diablo';
            $NotNull(document.head).appendChild(link);

            if (gameState === 'win' || gameState === 'lose') {
                const css = "body{font-family: 'Diablo', sans-serif !important;}";
                const head = document.head || document.getElementsByTagName('head')[0];
                const style = document.createElement('style');
                head.appendChild(style);
                style.type = 'text/css';
                // $FlowIgnore - its not clear to me how this works since there's not a styleSheet property on HTMLStyleElement
                if (style.styleSheet) {
                    // This is required for IE8 and below.
                    // $FlowIgnore
                    style.styleSheet.cssText = css;
                } else {
                    style.appendChild(document.createTextNode(css));
                }
            }
        }
    }
    // if (puzzle_num === '688') {
    //     // delete the div with class='image-selector'
    //     const imageSelector = document.querySelector('.image-selector');
    //     if (imageSelector) {
    //         imageSelector.remove();
    //     }
    //     // delete all the divs with class='game-image'
    //     const gameImages = document.querySelectorAll('.game-image');
    //     if (gameImages) {
    //         gameImages.forEach((gameImage) => {
    //             gameImage.remove();
    //         });
    //     }
    // }
    // if (puzzle_num === '611') {
    //     if (gameState === 'win' || gameState === 'lose') {
    //         // find the button with id 'share-results-btn' and append the following html element after it
    //         // <p> Want a free chance to win today's game? Scroll down below to click on our social media links for more details! </p>
    //         // only append it once
    //         const shareResultsBtn = document.getElementById('share-results-btn');
    //         const giveway = document.getElementById('giveway');
    //         if (shareResultsBtn && !giveway) {
    //             const p = document.createElement('p');
    //             p.className = 'game-info';
    //             p.id = 'giveway';
    //             p.innerHTML =
    //                 "Want a free chance to win today's game? Scroll down below to click on our social media links for more details!";
    //             shareResultsBtn.parentNode.insertBefore(p, shareResultsBtn.nextSibling);
    //         }
    //     }
    // }
    // if (puzzle_num === '496') {
    //     if (gameState === 'win' || gameState === 'lose') {
    //         let elementToChange = document.getElementsByTagName('head')[0];
    //         elementToChange.style.cursor = "url('/img/Pointan.cur'), auto";
    //     }
    // }
};

export { handleCustomPuzzleLogic };
