import React from 'react';

const BackloggdButton = ({ link }) => {
    const style = {
        background: 'rgb(234, 55, 122)',
        cursor: 'pointer',
        textAlign: 'center',
        textDecoration: 'none',
        maxWidth: '100%',
        padding: '0 2px',
        overflow: 'hidden',
    };

    const linkStyle = {
        textDecoration: 'none',
        display: 'block',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        fontSize: '16px',
        lineHeight: '1.2',
        padding: '8px 0',
    };

    return (
        <h3 style={{ textAlign: 'center' }}>
            <div
                className="mainButton share-results-btn replay-previous-days"
                id="add-a-game"
                data-toggle="modal"
                data-target="#add-a-game-modal"
                style={style}
            >
                <a href={link + '?utm_source=guessthegame&utm_medium=web'} style={linkStyle}>
                    ➕ Log, Rate, & Review on your Backloggd
                </a>
            </div>
        </h3>
    );
};

export default BackloggdButton;
