/* eslint-disable no-useless-escape */
// @flow

export type TGTLAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
    pixelation_level: number,
};

type TGTLAnswers = {
    [string]: TGTLAnswer
};

export const answers: TGTLAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Mastercard"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MA-c8ce4564.png?t=1720244492',
        uncover_order: [2, 5, 4, 7],
        pixelation_level: '30',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Facebook", "Meta"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/FB-2d2223ad.png?t=1633076955&download=true',
        uncover_order: [0, 2, 4, 6],
        pixelation_level: '20',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["McDonald's"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MCD-4fd8e96f.png?t=1647435923',
        uncover_order: [7, 5, 1, 0],
        pixelation_level: '30',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Shell"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/SHEL-a71e2d12.png?t=1633506726',
        uncover_order: [2, 8, 4, 3],
        pixelation_level: '30',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Coca-Cola", "Coke"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/KO-b23a2a5e.png?t=1720244492',
        uncover_order: [8, 0, 4, 5],
        pixelation_level: '30',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["LinkedIn"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/linkedin-2c3012a9.png?t=1720244494',
        uncover_order: [2, 6, 4, 8],
        pixelation_level: '30',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Opera"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/OPRA-305c175c.png?t=1720244493',
        uncover_order: [6, 5, 0, 1],
        pixelation_level: '30',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["BMW"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BMW.DE-56e4d00d.png?t=1720244491',
        uncover_order: [6, 8, 4, 2],
        pixelation_level: '25',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Wells Fargo"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WFC-a4743c33.png?t=1720244494',
        uncover_order: [6, 2, 4, 5],
        pixelation_level: '7',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Rolls-Royce Holdings", "Rolls-Royce"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/RR.L-b61419e9.png?t=1720244493',
        uncover_order: [2, 7, 0, 4],
        pixelation_level: '9',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["LG Energy Solution", "LG", "LG Electronics"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/373220.KS-93d78350.png?t=1720244490',
        uncover_order: [2, 6, 0, 4],
        pixelation_level: '30',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Netflix"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/NFLX-7466b597.png?t=1720244493',
        uncover_order: [6, 5, 4, 2],
        pixelation_level: '30',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Procter & Gamble"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PG-b828a7df.png?t=1720244493',
        uncover_order: [7, 0, 3, 5],
        pixelation_level: '30',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Domino's Pizza"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DPZ-9c373038.png?t=1720244491',
        uncover_order: [8, 1, 3, 4],
        pixelation_level: '30',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Dell"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DELL-f7f7f0be.png?t=1720244491',
        uncover_order: [6, 1, 2, 5],
        pixelation_level: '30',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Jollibee"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/JBFCF_BIG-6f9dd9a2.png?t=1720244492',
        uncover_order: [1, 6, 2, 8],
        pixelation_level: '30',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Yelp"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/YELP-d704c977.png?t=1720244494',
        uncover_order: [2, 6, 4, 3],
        pixelation_level: '30',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Salesforce"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/CRM-74b365dd.png?t=1720244491',
        uncover_order: [8, 7, 3, 5],
        pixelation_level: '30',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["General Electric", "GE"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/GE-0f5d8584.png?t=1720244492',
        uncover_order: [8, 1, 4, 7],
        pixelation_level: '30',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["PayPal"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PYPL-3570673e.png?t=1720244493',
        uncover_order: [6, 3, 4, 2],
        pixelation_level: '30',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Unilever"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/UL-1dae83e7.png?t=1720244494',
        uncover_order: [8, 1, 3, 4],
        pixelation_level: '30',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["ING"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ING-8c32bac2.png?t=1720244492',
        uncover_order: [2, 3, 8, 0],
        pixelation_level: '30',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Zoom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ZM-b326e02f.png?t=1720244494',
        uncover_order: [6, 0, 2, 3],
        pixelation_level: '30',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["eBay"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/EBAY-a442b3a2.png?t=1720244491',
        uncover_order: [1, 7, 5, 4],
        pixelation_level: '30',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Walmart"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WMT-0d8ecd74.png?t=1720244494',
        uncover_order: [6, 0, 4, 2],
        pixelation_level: '30',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["Alibaba"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BABA-2884ac04.png?t=1720244490',
        uncover_order: [0, 8, 4, 2],
        pixelation_level: '30',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["T-Mobile"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/TMUS-c281226a.png?t=1720244494',
        uncover_order: [6, 5, 4, 2],
        pixelation_level: '30',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Adobe"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ADBE-fb158b30.png?t=1720244490',
        uncover_order: [5, 6, 4, 1],
        pixelation_level: '30',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Verizon"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/VZ-13e34d8e.png?t=1720244494',
        uncover_order: [1, 5, 4, 3],
        pixelation_level: '30',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Tesla"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/TSLA-6da550e5.png?t=1722952499',
        uncover_order: [0, 3, 7, 4],
        pixelation_level: '30',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Globe Telecom, Inc.", "Globe Telecom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/GTMEY-a1d3b2cf.png?t=1720244492',
        uncover_order: [3, 8, 5, 4],
        pixelation_level: '30',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Levi Strauss", "Levi's"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/LEVI-e8800e7f.png?t=1720244492',
        uncover_order: [6, 7, 8, 0],
        pixelation_level: '30',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Qualcomm"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/QCOM-515e1eda.png?t=1720244493',
        uncover_order: [6, 4, 1, 8],
        pixelation_level: '30',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Capcom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/9697.T-72f98d81.png?t=1720244490',
        uncover_order: [4, 5, 6, 0],
        pixelation_level: '30',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Western Union"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WU-1a8f4ddc.png?t=1720244494',
        uncover_order: [6, 8, 7, 3],
        pixelation_level: '30',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Nestl\u00e9", "Nestle"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/NESN.SW-a5b680cf.png?t=1720244493',
        uncover_order: [6, 8, 2, 5],
        pixelation_level: '9',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["FedEx"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/FDX-35c19ad4.png?t=1720244491',
        uncover_order: [0, 3, 6, 1],
        pixelation_level: '30',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Kellogg's", "Kellog"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/K-b27c0809.png?t=1720244492',
        uncover_order: [6, 2, 1, 3],
        pixelation_level: '30',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Visa"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/V-05214186.png?t=1720244494',
        uncover_order: [6, 4, 3, 2],
        pixelation_level: '30',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["DHL Group", "DHL"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DHL.DE-8c515c8f.png?t=1720244491',
        uncover_order: [6, 7, 2, 1],
        pixelation_level: '30',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Xiaomi"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/XIACF-9058dc7f.png?t=1720244494',
        uncover_order: [1, 7, 5, 3],
        pixelation_level: '30',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["El Pollo Loco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/LOCO-8a6d92b6.png?t=1720244492',
        uncover_order: [2, 0, 8, 4],
        pixelation_level: '30',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Bandai Namco", "Bandai"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/7832.T-afbe2170.png?t=1720244490',
        uncover_order: [2, 1, 0, 3],
        pixelation_level: '30',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["Golden State Warriors"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2018/03/Golden-State-Warriors-logo.png',
        uncover_order: [2, 8, 0, 6],
        pixelation_level: '30',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Lawson", "Lawson Station"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/2651.T_BIG-70b1c1d5.png?t=1720244490',
        uncover_order: [0, 7, 3, 1],
        pixelation_level: '15',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Costco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/COST-180a6d1f.png?t=1720244491',
        uncover_order: [7, 3, 0, 1],
        pixelation_level: '30',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Red Bull"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2021/04/Red-Bull-logo.png',
        uncover_order: [0, 4, 1, 7],
        pixelation_level: '45',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Ford", "Ford Motor"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2018/02/Ford-Logo-2003.png',
        uncover_order: [0, 2, 1, 8],
        pixelation_level: '30',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Kappa"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2020/03/Kappa-logo.png',
        uncover_order: [0, 5, 4, 7],
        pixelation_level: '14',
    },
    '50': {
        content: 'McDonalds Corporation is an American multinational fast food chain, founded in 1940 as a restaurant operated by Richard and Maurice McDonald, in San Bernardino, California, United States.',
        submitted_by: 'https://www.mcdonalds.com/us/en-us.html',
        answers: ["McDonald\u2019s"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/McDonald%27s_Golden_Arches.svg/1200px-McDonald%27s_Golden_Arches.svg.png',
        uncover_order: [4, 3, 5, 6],
        pixelation_level: '30',
    },
    '51': {
        content: 'American Express Company is an American bank holding company and multinational financial services corporation that specializes in payment cards. It is headquartered at 200 Vesey Street, also known as American Express Tower, in the Battery Park City neighborhood of Lower Manhattan.',
        submitted_by: 'https://www.americanexpress.com/',
        answers: ["American Express"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/AXP-630b1f5e.png?t=1720244490',
        uncover_order: [0, 2, 4, 5],
        pixelation_level: '30',
    },
    '52': {
        content: 'Target Corporation is an American retail corporation that operates a chain of discount department stores and hypermarkets, headquartered in Minneapolis, Minnesota. It is the seventh-largest retailer in the United States, and a component of the S and P 500 Index.',
        submitted_by: 'https://www.target.com/',
        answers: ["Target"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2017/06/Target-Logo.png',
        uncover_order: [2, 1, 5, 7],
        pixelation_level: '30',
    },
    '53': {
        content: 'Burger King Corporation is an American multinational chain of hamburger fast food restaurants. Headquartered in Miami-Dade County, Florida, the company was founded in 1953 as Insta-Burger King, a Jacksonville, Florida–based restaurant chain.',
        submitted_by: 'https://www.bk.com/',
        answers: ["Burger King"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://purepng.com/public/uploads/large/burger-king-logo-xua.png',
        uncover_order: [0, 8, 2, 6],
        pixelation_level: '30',
    },
    '54': {
        content: 'The Milwaukee Electric Tool Corporation is a multi-national company that develops, manufactures, and markets power tools. Milwaukee tools are currently manufactured in China, Germany, Mexico, the United States and Vietnam.',
        submitted_by: 'https://www.milwaukeetool.com/',
        answers: ["Milwaukee"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://i.pinimg.com/originals/43/fd/3e/43fd3e20cfd21e0d7f228efd290f5f95.png',
        uncover_order: [1, 2, 3, 4],
        pixelation_level: '30',
    },
    '55': {
        content: 'The Philadelphia 76ers, also known colloquially as the Sixers, are an American professional basketball team based in the Philadelphia metropolitan area. The 76ers compete in the National Basketball Association as a member of the Atlantic Division of the Eastern Conference.',
        submitted_by: 'https://www.nba.com/sixers/',
        answers: ["Philadelphia 76ers"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/en/thumb/0/0e/Philadelphia_76ers_logo.svg/1200px-Philadelphia_76ers_logo.svg.png',
        uncover_order: [8, 6, 1, 5],
        pixelation_level: '30',
    },
    '56': {
        content: 'Kit Kat is a chocolate-covered wafer bar confection created by Rowntree s of York, England. It is produced globally by Nestlé, except in the United States, where it is made under licence by the H. B. Reese Candy Company, a division of the Hershey Company.',
        submitted_by: 'https://www.nestle.com/',
        answers: ["KitKat"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/12/Nestle-Kit-Kat-Logo-1995-2004-700x394.png',
        uncover_order: [6, 8, 2, 0],
        pixelation_level: '15',
    },
    '57': {
        content: 'Java is a high-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.',
        submitted_by: 'https://www.java.com/',
        answers: ["Java"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/logos/large/2x/java-logo-png-transparent.png',
        uncover_order: [5, 1, 4, 6],
        pixelation_level: '25',
    },
    '58': {
        content: 'Chevrolet is an American automobile division of the manufacturer General Motors. Louis Chevrolet, Arthur Chevrolet and ousted General Motors founder William C. Durant started the company on November 3, 1911 as the Chevrolet Motor Car Company.',
        submitted_by: 'https://www.chevrolet.com/',
        answers: ["Chevrolet"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1000logos.net/wp-content/uploads/2019/12/Chevrolet-logo.png',
        uncover_order: [3, 5, 4, 8],
        pixelation_level: '30',
    },
    '59': {
        content: 'Safari is a web browser developed by Apple. It is built into Apples operating systems, including macOS, iOS, iPadOS and visionOS, and uses Apples open-source browser engine WebKit, which was derived from KHTML. Safari was introduced in Mac OS X Panther in January 2003.',
        submitted_by: 'https://www.apple.com/safari/',
        answers: ["Safari"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2021/10/Safari-Logo.png',
        uncover_order: [8, 6, 2, 4],
        pixelation_level: '30',
    },
    '60': {
        content: 'ESPN is an American international basic cable sports channel owned by The Walt Disney Company and Hearst Communications through the joint venture ESPN Inc. The company was founded in 1979 by Bill Rasmussen, Scott Rasmussen and Ed Eagan. ESPN broadcasts primarily from studio facilities located in Bristol, Connecticut.',
        submitted_by: 'https://www.espn.com/',
        answers: ["ESPN"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logosmarcas.net/wp-content/uploads/2020/12/ESPN-Logo.png',
        uncover_order: [8, 7, 1, 3],
        pixelation_level: '35',
    },
    '61': {
        content: 'Skype is a proprietary telecommunications application operated by Skype Technologies, a division of Microsoft, best known for VoIP-based videotelephony, videoconferencing and voice calls. It also has instant messaging, file transfer, debit-based calls to landline and mobile telephones, and other features.',
        submitted_by: 'https://www.skype.com/en/',
        answers: ["Skype"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/09/Skype-Logo.png',
        uncover_order: [2, 6, 0, 8],
        pixelation_level: '50',
    },
    '62': {
        content: 'IKEA is a Swedish multinational conglomerate that designs and sells ready-to-assemble furniture, kitchen appliances, decoration, home accessories, and various other goods and home services.',
        submitted_by: 'https://www.ikea.com/us/en/',
        answers: ["IKEA"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ikea-b2625d20.png?t=1720244494',
        uncover_order: [8, 7, 3, 0],
        pixelation_level: '50',
    },
    '63': {
        content: 'Adobe Photoshop is a raster graphics editor developed and published by Adobe Inc. for Windows and macOS. It was originally created in 1987 by Thomas and John Knoll. Since then, the software has become the most used tool for professional digital art, especially in raster graphics editing.',
        submitted_by: 'https://www.adobe.com/products/photoshop.html',
        answers: ["Adobe Photoshop CC", "Adobe Photoshop"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/A/Adobe-Photoshop-CC-logo-01.png',
        uncover_order: [7, 2, 8, 0],
        pixelation_level: '25',
    },
    '64': {
        content: 'Converse is an American lifestyle brand that markets, distributes, and licenses footwear, apparel, and accessories. Founded by Marquis Mills Converse in 1908 as the Converse Rubber Shoe Company in Malden, Massachusetts',
        submitted_by: 'https://www.converse.com/c/all-stars',
        answers: ["Converse All Star", "Converse"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/logos/large/2x/converse-all-star-1-logo-png-transparent.png',
        uncover_order: [3, 5, 4, 8],
        pixelation_level: '30',
    },
    '65': {
        content: 'The St. Louis Cardinals are an American professional baseball team based in St. Louis. The Cardinals compete in Major League Baseball as a member club of the National League Central Division. Since the 2006 season, the Cardinals have played their home games at Busch Stadium in downtown St. Louis.',
        submitted_by: 'https://www.mlb.com/cardinals',
        answers: ["St Louis Cardinals", "Cardinals"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/S/St-Louis-Cardinals-logo-01.png',
        uncover_order: [6, 5, 1, 8],
        pixelation_level: '30',
    },
    '66': {
        content: 'ASUSTeK Computer Inc. is a Taiwanese multinational computer, phone hardware and electronics manufacturer headquartered in Beitou District, Taipei, Taiwan.',
        submitted_by: 'https://www.asus.com/us/',
        answers: ["ASUS"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/07/Asus-Logo-1995-present.png',
        uncover_order: [0, 5, 2, 3],
        pixelation_level: '30',
    },
    '67': {
        content: 'The Los Angeles Dodgers are an American professional baseball team based in Los Angeles. The Dodgers compete in Major League Baseball as a member club of the National League West division.',
        submitted_by: 'https://www.mlb.com/dodgers/',
        answers: ["Los Angeles Dodgers"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Los_Angeles_Dodgers_Logo.svg/1200px-Los_Angeles_Dodgers_Logo.svg.png',
        uncover_order: [6, 7, 1, 2],
        pixelation_level: '30',
    },
    '68': {
        content: 'New Balance Athletics, Inc., best known as simply New Balance, is one of the world s major sports footwear and apparel manufacturers. Based in Boston, Massachusetts, the multinational corporation was founded in 1906 as the New Balance Arch Support Company.',
        submitted_by: 'https://www.newbalance.com/',
        answers: ["New Balance"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/09/New-Balance-Logo-2006-2008.png',
        uncover_order: [0, 2, 4, 8],
        pixelation_level: '30',
    },
    '69': {
        content: 'KFC Corporation, doing business as KFC, is an American fast food restaurant chain that specializes in fried chicken. Headquartered in Louisville, Kentucky, it is the world s second-largest restaurant chain after McDonald s, with over 30,000 locations globally in 150 countries as of April 2024.',
        submitted_by: 'https://www.kfc.com/',
        answers: ["KFC", "Kentucky Fried Chicken"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.a2c026226f25e15add2c921d7349b224?rik=qZNIvjrvTi1XrA&riu=http%3a%2f%2fwww.stickpng.com%2fassets%2fimages%2f58429977a6515b1e0ad75ade.png&ehk=X2VKCpRD1MSWJgfSo1LxqYpVP5%2b%2b9zpjTaqYn%2fS6kJw%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [2, 3, 6, 8],
        pixelation_level: '30',
    },
    '70': {
        content: 'Suzuki Motor Corporation is a Japanese multinational mobility manufacturer headquartered in Hamamatsu, Shizuoka. It manufactures automobiles, motorcycles, all-terrain vehicles, outboard marine engines, wheelchairs and a variety of other small internal combustion engines.',
        submitted_by: 'https://suzuki.com/',
        answers: ["Suzuki"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logodownload.org/wp-content/uploads/2017/04/suzuki-logo-1-1.png',
        uncover_order: [0, 2, 3, 8],
        pixelation_level: '30',
    },
    '71': {
        content: 'The White House is the official residence and workplace of the president of the United States. Located at 1600 Pennsylvania Avenue NW in Washington, D.C., it has served as the residence of every U.S. president since John Adams in 1800 when the national capital was moved from Philadelphia.',
        submitted_by: 'https://www.whitehouse.gov/',
        answers: ["US White House", "White House"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.a22054dbd9ff9b01c7b75d04bec12e87?rik=WygmaCoQOOM4zA&riu=http%3a%2f%2flogonoid.com%2fimages%2fwhite-house-logo.png&ehk=QtkUa4z4DIRi9%2bYPLh9rPff1WvTMAofIF32ugD15P0A%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [2, 8, 4, 5],
        pixelation_level: '30',
    },
    '72': {
        content: 'Spotify is a Swedish audio streaming and media service provider founded on 23 April 2006 by Daniel Ek and Martin Lorentzon. It is one of the largest music streaming service providers, with over 615 million monthly active users, including 239 million paying subscribers, as of March 2024.',
        submitted_by: 'http://www.spotify.com',
        answers: ["Spotify"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logospng.org/download/spotify/logo-spotify-icon-4096.png',
        uncover_order: [0, 1, 2, 4],
        pixelation_level: '60',
    },
    '73': {
        content: 'The Chicago Cubs are an American professional baseball team based in Chicago. The Cubs compete in Major League Baseball as part of the National League Central division. The club plays its home games at Wrigley Field, which is located on Chicago s North Side.',
        submitted_by: 'https://www.mlb.com/cubs/',
        answers: ["Chicago Cubs", "Cubs"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logodownload.org/wp-content/uploads/2020/09/chicago-cubs-logo-2048x2048.png',
        uncover_order: [7, 3, 1, 8],
        pixelation_level: '30',
    },
    '74': {
        content: 'Land Rover is a British brand of predominantly four-wheel drive, off-road capable vehicles, owned by multinational car manufacturer Jaguar Land Rover, since 2008 a subsidiary of India s Tata Motors. JLR builds Land Rovers in Brazil, China, India, Slovakia, and the United Kingdom.',
        submitted_by: 'https://www.landroverusa.com/',
        answers: ["Land Rover"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2021/09/Land-Rover-Logo-1986-present.png',
        uncover_order: [2, 6, 0, 8],
        pixelation_level: '30',
    },
    '75': {
        content: 'Google Play, also known as the Google Play Store or Play Store and formerly Android Market, is a digital distribution service operated and developed by Google.',
        submitted_by: 'https://play.google.com/',
        answers: ["Google Play"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/12/Google-Play-icon-logo.png',
        uncover_order: [4, 7, 1, 2],
        pixelation_level: '60',
    },
    '76': {
        content: 'Twix is a caramel shortbread chocolate bar made by Mars, Inc., consisting of a biscuit applied with other confectionery toppings and coatings. Twix are packaged with one, two, or four bars in a wrapper.',
        submitted_by: 'https://www.twix.com/',
        answers: ["Twix"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/T/Twix-logo-01.png',
        uncover_order: [5, 2, 3, 6],
        pixelation_level: '30',
    },
    '77': {
        content: 'HP Inc. is an American multinational information technology company headquartered in Palo Alto, California, that develops personal computers, printers and related supplies, as well as 3D printing solutions.',
        submitted_by: 'https://www.hp.com/',
        answers: ["HP"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://purepng.com/public/uploads/large/purepng.com-hp-logologobrand-logoiconslogos-2515199390657l82v.png',
        uncover_order: [8, 0, 2, 1],
        pixelation_level: '45',
    },
    '78': {
        content: 'Microsoft Excel is a spreadsheet editor developed by Microsoft for Windows, macOS, Android, iOS and iPadOS. It features calculation or computation capabilities, graphing tools, pivot tables, and a macro programming language called Visual Basic for Applications. Excel forms part of the Microsoft 365 suite of software.',
        submitted_by: 'https://www.microsoft.com/en-us/microsoft-365/excel',
        answers: ["Microsoft Excel"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandslogos.com/wp-content/uploads/images/large/microsoft-excel-2013-logo.png',
        uncover_order: [6, 0, 7, 2],
        pixelation_level: '30',
    },
    '79': {
        content: 'The Washington Nationals are an American professional baseball team based in Washington, D.C. They compete in Major League Baseball as a member of the National League East division.',
        submitted_by: 'https://www.mlb.com/nationals/',
        answers: ["Washington Nationals"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.ae45e0fa3f2a96287c2d4e83abde9f84?rik=Pk7CzJWE%2bRFgIQ&riu=http%3a%2f%2flogos-download.com%2fwp-content%2fuploads%2f2016%2f04%2fWashington_Nationals_logo_logotype.png&ehk=wVoJIJppNDGFa58WjP2utc7YnPziB3EGLDpqqBFV82o%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [0, 6, 3, 4],
        pixelation_level: '50',
    },
    '80': {
        content: 'Starbucks Corporation is an American multinational chain of coffeehouses and roastery reserves headquartered in Seattle, Washington. It was founded in 1971, and is currently the worlds largest coffeehouse chain.',
        submitted_by: 'https://www.starbucks.com/',
        answers: ["Starbucks"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/09/Starbucks-Logo.png',
        uncover_order: [0, 2, 5, 8],
        pixelation_level: '45',
    },
    '81': {
        content: 'Fiat Automobiles S.p.A. is an Italian automobile manufacturer, formerly part of Fiat Chrysler Automobiles, and since 2021 a subsidiary of Stellantis through its Italian division Stellantis Europe.',
        submitted_by: 'https://www.fiat.com/',
        answers: ["Fiat"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2021/03/Fiat-Logo-2006-present.png',
        uncover_order: [6, 0, 5, 2],
        pixelation_level: '30',
    },
    '82': {
        content: 'The Fox News Channel, commonly known as Fox News, is an American multinational conservative news and political commentary television channel and website based in New York City. It is owned by Fox News Media, which itself is owned by the Fox Corporation.',
        submitted_by: 'https://www.foxnews.com/',
        answers: ["FOX News"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Fox_News_Channel_logo.svg/1200px-Fox_News_Channel_logo.svg.png',
        uncover_order: [0, 3, 7, 1],
        pixelation_level: '30',
    },
    '83': {
        content: 'Microsoft Teams is a proprietary business communication platform developed by Microsoft, as part of the Microsoft 365 family of products.',
        submitted_by: 'https://www.microsoft.com/en-us/microsoft-teams/group-chat-software',
        answers: ["Microsoft Teams", "Teams"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2021/04/Microsoft-Teams-Logo.png',
        uncover_order: [5, 0, 6, 3],
        pixelation_level: '50',
    },
    '84': {
        content: 'The Notre Dame Fighting Irish football team is the intercollegiate football team representing the University of Notre Dame in Notre Dame, Indiana, north of the city of South Bend, Indiana. The team plays its home games at the campus Notre Dame Stadium, which has a capacity of 77,622.',
        submitted_by: 'https://fightingirish.com/',
        answers: ["Notre Dame Fighting Irish", "Notre Dame"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Notre_Dame_Fighting_Irish_logo.svg/1200px-Notre_Dame_Fighting_Irish_logo.svg.png',
        uncover_order: [8, 2, 6, 0],
        pixelation_level: '18',
    },
    '85': {
        content: 'Android is a mobile operating system based on a modified version of the Linux kernel and other open-source software, designed primarily for touchscreen mobile devices such as smartphones and tablets.',
        submitted_by: 'https://www.android.com/',
        answers: ["Android", "Android Robot"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://1.bp.blogspot.com/-F34_na66lGk/Xk1XkBSwLKI/AAAAAAAAJek/Mo1Ffj7r6X4mb6e-8OEHDruzv6UQpii9wCLcBGAsYHQ/s1600/Android%2BRobot%2BLogo.png',
        uncover_order: [0, 2, 7, 4],
        pixelation_level: '18',
    },
    '86': {
        content: 'Twitch is an American video live streaming service that focuses on video game live streaming, including broadcasts of esports competitions, in addition to offering music broadcasts, creative content, and \"in real life\" streams.',
        submitted_by: 'https://www.twitch.tv/',
        answers: ["Twitch"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://freelogopng.com/images/all_img/1656152094twitch-icon-png.png',
        uncover_order: [0, 6, 8, 1],
        pixelation_level: '30',
    },
    '87': {
        content: 'The Oakland Athletics are an American professional baseball team based in Oakland. The Athletics compete in Major League Baseball as a member club of the American League West division. The team plays its home games at the Oakland Coliseum.',
        submitted_by: 'https://www.mlb.com/athletics/',
        answers: ["Oakland Athletics"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-download.com/wp-content/uploads/2016/04/Oakland_Athletics_logo_logotype.png',
        uncover_order: [3, 5, 0, 8],
        pixelation_level: '30',
    },
    '88': {
        content: 'WeChat or Weixin in Chinese is a Chinese instant messaging, social media, and mobile payment app developed by Tencent.',
        submitted_by: 'https://www.wechat.com/',
        answers: ["WeChat"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://clipartcraft.com/images/wechat-logo-transparent-png-5.png',
        uncover_order: [6, 7, 5, 3],
        pixelation_level: '30',
    },
    '89': {
        content: 'Discord is an instant messaging and VoIP social platform which allows communication through voice calls, video calls, text messaging, and media. Communication can be private or take place in virtual communities called servers.',
        submitted_by: 'https://discord.com/',
        answers: ["Discord"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logodownload.org/wp-content/uploads/2017/11/discord-logo-2-1.png',
        uncover_order: [0, 2, 1, 4],
        pixelation_level: '70',
    },
    '90': {
        content: 'H & M Hennes & Mauritz AB is a multinational clothing company based in Sweden that focuses on fast-fashion clothing. As of 23 June 2022, H&M Group operated in 75 geographical markets with 4,801 stores under the various company brands, with 107,375 full-time equivalent positions.',
        submitted_by: 'https://www.hm.com/',
        answers: ["H&M"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/04/HM-Logo-1968-1999.png',
        uncover_order: [0, 8, 2, 6],
        pixelation_level: '45',
    },
    '91': {
        content: 'Alfa Romeo Automobiles S.p.A. is an Italian luxury carmaker known for its sports-oriented vehicles, strong auto racing heritage, and iconic design. Headquartered in Turin, Italy, it is a subsidiary of Stellantis Europe and one of 14 brands of multinational automotive company Stellantis.',
        submitted_by: 'https://www.alfaromeousa.com/',
        answers: ["Alfa Romeo"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.ca97db79d89967b7c2c305dd3d090ccb?rik=%2bD9hBVJiSbbyrg&riu=http%3a%2f%2fwww.stickpng.com%2fassets%2fimages%2f580b57fcd9996e24bc43c468.png&ehk=tSJ9vld8rKx%2f2JTCvwHacxvFjzarLHIWVVVVT3JLDDI%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [8, 6, 0, 2],
        pixelation_level: '18',
    },
    '92': {
        content: 'The National Aeronautics and Space Administration is an independent agency of the U.S. federal government responsible for the civil space program, aeronautics research, and space research.',
        submitted_by: 'https://www.nasa.gov/',
        answers: ["NASA"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logodownload.org/wp-content/uploads/2019/03/nasa-logo-1.png',
        uncover_order: [2, 6, 8, 3],
        pixelation_level: '40',
    },
    '93': {
        content: 'The Minnesota Twins are an American professional baseball team based in Minneapolis. The Twins compete in Major League Baseball as a member club of the American League Central Division.',
        submitted_by: 'https://www.mlb.com/twins',
        answers: ["Minnesota Twins"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.c730bd04291ba8904faf598ff7f9a2f6?rik=Me6Y%2bergbl%2fnhw&riu=http%3a%2f%2flogos-download.com%2fwp-content%2fuploads%2f2016%2f04%2fMinnesota_Twins_logo_logotype_emblem_symbol.png&ehk=xyd%2fc3Jwm6tv2jUUH%2bjGgAbvnlsJHkiWQiZxn12Xy4w%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [8, 6, 3, 2],
        pixelation_level: '50',
    },
    '94': {
        content: 'Barbie is a fashion doll created by American businesswoman Ruth Handler, manufactured by American toy and entertainment company Mattel and introduced on March 9, 1959. The toy was based on the German Bild Lilli doll which Handler had purchased while in Europe.',
        submitted_by: 'https://www.imdb.com/title/tt1517268/',
        answers: ["Barbie"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.de67b9591cb48d8407f02a3b3ad43314?rik=ajWJkUk05I%2fdwg&riu=http%3a%2f%2flogos-download.com%2fwp-content%2fuploads%2f2016%2f07%2fBarbie_logo.png&ehk=Uk%2futOOsUNYUtSGIQ6QRlf5B5ObFTrlvK0y65CUo%2f0M%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [1, 0, 2, 3],
        pixelation_level: '30',
    },
    '95': {
        content: 'The Gap, Inc., commonly known as Gap Inc. or Gap, is an American worldwide clothing and accessories retailer. Gap was founded in 1969 by Donald Fisher and Doris F. Fisher and is headquartered in San Francisco, California. The company operates four primary divisions: Gap, Banana Republic, Old Navy, and Athleta.',
        submitted_by: 'https://www.gap.com/',
        answers: ["GAP"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/G/GAP-logo-01.png',
        uncover_order: [8, 6, 2, 0],
        pixelation_level: '30',
    },
    '96': {
        content: 'Harvard University is a private Ivy League research university in Cambridge, Massachusetts. Founded in 1636 as Harvard College and named for its first benefactor, Puritan clergyman John Harvard, it is the oldest institution of higher learning in the United States.',
        submitted_by: 'https://www.harvard.edu/',
        answers: ["Harvard University", "Harvard"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/12/Harvard-Logo.png',
        uncover_order: [0, 2, 3, 8],
        pixelation_level: '30',
    },
    '97': {
        content: 'Rolex SA is a Swiss watch brand and manufacturer based in Geneva, Switzerland. Founded in 1905 as Wilsdorf and Davis by German businessman Hans Wilsdorf and his brother-in-law Alfred Davis in London, the company registered Rolex as the brand name of its watches in 1908 and became Rolex Watch Co. Ltd. in 1915.',
        submitted_by: 'https://www.rolex.com/',
        answers: ["Rolex"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/images/large/2x/rolex-logo-png-transparent.png',
        uncover_order: [0, 2, 1, 5],
        pixelation_level: '25',
    },
    '98': {
        content: 'Vodafone Group is a British multinational telecommunications company. Its registered office and global headquarters are in Newbury, Berkshire, England. It predominantly operates services in Asia, Africa, Europe, and Oceania.',
        submitted_by: 'https://www.vodafone.com/',
        answers: ["Vodafone"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/logos/large/2x/vodafone-logo-png-transparent.png',
        uncover_order: [2, 3, 0, 8],
        pixelation_level: '30',
    },
    '99': {
        content: 'Pfizer Inc. is an American multinational pharmaceutical and biotechnology corporation headquartered at The Spiral in Manhattan, New York City. The company was established in 1849 in New York by two German entrepreneurs, Charles Pfizer and his cousin Charles F. Erhart.',
        submitted_by: 'https://www.pfizer.com/',
        answers: ["Pfizer"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logospng.org/download/pfizer/logo-pfizer-1024.png',
        uncover_order: [0, 2, 4, 5],
        pixelation_level: '30',
    },
    '100': {
        content: 'ChatGPT, which stands for Chat Generative Pre-trained Transformer, is a large language model-based chatbot developed by OpenAI, which enables users to refine and steer a conversation towards a desired length, format, style, level of detail, and language.',
        submitted_by: 'https://openai.com/chatgpt/',
        answers: ["ChatGPT", "OpenAI"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://uploads-ssl.webflow.com/647744cb31353296b2972487/647744cb31353296b2972779_chatgpt-icon.png',
        uncover_order: [0, 1, 2, 3],
        pixelation_level: '30',
    },
    '101': {
        content: 'The Sacramento Kings are an American professional basketball team based in Sacramento, California. The Kings compete in the National Basketball Association as a member of the Pacific Division of the Western Conference.',
        submitted_by: 'https://www.nba.com/kings/',
        answers: ["Sacramento Kings"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/images/large/2x/sacramento-kings-logo-transparent.png',
        uncover_order: [7, 6, 0, 5],
        pixelation_level: '30',
    },
    '102': {
        content: 'Manchester United Football Club, commonly referred to as Man United, or simply United, is a professional football club based in Old Trafford, Greater Manchester, England.',
        submitted_by: 'https://www.manutd.com/',
        answers: ["Manchester United"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logodownload.org/wp-content/uploads/2016/10/manchester-united-logo-0.png',
        uncover_order: [2, 0, 6, 1],
        pixelation_level: '7',
    },
    '103': {
        content: 'Atari is a brand name that has been owned by several entities since its inception in 1972. It is currently owned by French company Atari SA (formerly Infogrames) through a subsidiary named Atari Interactive.',
        submitted_by: 'https://atari.com/',
        answers: ["Atari"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://pnghq.com/wp-content/uploads/atari-logo-png-transparent-svg-vector-png-transparent-background.png',
        uncover_order: [0, 2, 3, 1],
        pixelation_level: '30',
    },
    '104': {
        content: 'Telegram Messenger, commonly known as Telegram, is a cloud-based, encrypted, cross-platform, instant messaging service. It was originally launched for iOS on 14 August 2013 and Android on 20 October 2013.',
        submitted_by: 'https://telegram.org/',
        answers: ["Telegram"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn2.iconfinder.com/data/icons/social-media-2420/512/Telegram-1024.png',
        uncover_order: [6, 0, 8, 3],
        pixelation_level: '30',
    },
    '105': {
        content: 'The Los Angeles Lakers are an American professional basketball team based in Los Angeles. The Lakers compete in the National Basketball Association as a member of the Pacific Division of the Western Conference.',
        submitted_by: 'https://www.nba.com/lakers',
        answers: ["Los Angeles Lakers", "Lakers"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-download.com/wp-content/uploads/2016/04/Los_Angeles_Lakers_logo_logotype_emblem.png',
        uncover_order: [6, 8, 0, 5],
        pixelation_level: '50',
    },
    '106': {
        content: 'Superman is a superhero who appears in American comic books published by DC Comics. The character was created by writer Jerry Siegel and artist Joe Shuster, and debuted in the comic book Action Comics #1.',
        submitted_by: 'https://www.supermanhomepage.com/',
        answers: ["Superman"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Superman_S_symbol.svg/2560px-Superman_S_symbol.svg.png',
        uncover_order: [6, 8, 5, 0],
        pixelation_level: '50',
    },
    '107': {
        content: 'Automobiles Ettore Bugatti was a German then French manufacturer of high-performance automobiles. The company was founded in 1909 in the then-German city of Molsheim, Alsace, by the Italian-born industrial designer Ettore Bugatti. The cars were known for their design beauty and numerous race victories.',
        submitted_by: 'https://www.bugatti.com/',
        answers: ["Bugatti"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.carlogos.org/logo/Bugatti-logo-2560x1440.png',
        uncover_order: [0, 2, 6, 5],
        pixelation_level: '30',
    },
    '108': {
        content: 'The National Security Agency is an intelligence agency of the United States Department of Defense, under the authority of the Director of National Intelligence.',
        submitted_by: 'https://www.nsa.gov/',
        answers: ["National Security Agency (NSA)", "NSA", "National Security Agency"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandslogos.com/wp-content/uploads/images/large/nsa-national-security-agency-logo.png',
        uncover_order: [8, 5, 4, 3],
        pixelation_level: '30',
    },
    '109': {
        content: 'Capital A Berhad, operating as AirAsia, is a Malaysian multinational low-cost airline headquartered near Kuala Lumpur, Malaysia. It is the largest airline in Malaysia by fleet size and destinations. AirAsia operates scheduled domestic and international flights to more than 166 destinations spanning 25 countries.',
        submitted_by: 'https://www.airasia.com/en/gb',
        answers: ["AirAsia"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-download.com/wp-content/uploads/2016/05/AirAsia_logo_logotype_circle.png',
        uncover_order: [8, 6, 0, 5],
        pixelation_level: '50',
    },
    '110': {
        content: 'Tide is an American brand of laundry detergent manufactured and marketed by Procter & Gamble',
        submitted_by: 'https://tide.com/',
        answers: ["Tide"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-download.com/wp-content/uploads/2016/03/Tide_logo.png',
        uncover_order: [7, 2, 0, 5],
        pixelation_level: '80',
    },
    '111': {
        content: 'Ray-Ban is a brand of luxury sunglasses and eyeglasses created in 1936 by Bausch & Lomb. The brand is best known for its Wayfarer and Aviator lines of sunglasses.',
        submitted_by: 'https://www.ray-ban.com/',
        answers: ["Ray Ban", "RayBan"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/11/Rayban-Logo.png',
        uncover_order: [8, 1, 2, 7],
        pixelation_level: '50',
    },
    '112': {
        content: 'Corona is a Mexican brand of beer produced by Grupo Modelo in Mexico and exported to markets around the world. Constellation Brands is the exclusive licensee and sole importer of Corona in the fifty states of the United States, Washington, D.C., and Guam.',
        submitted_by: 'https://www.coronausa.com/',
        answers: ["Corona Extra", "Corona"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/03/Corona-Extra-Logo.png',
        uncover_order: [0, 2, 8, 1],
        pixelation_level: '30',
    },
    '113': {
        content: 'Holiday Inn is a chain of hotels based in Atlanta, Georgia and a brand of IHG Hotels & Resorts. The chain was founded in 1952 by Kemmons Wilson, who opened the first location in Memphis, Tennessee.',
        submitted_by: 'https://www.ihg.com/',
        answers: ["Holiday Inn"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.b2f9114543617375c949b8bb55a44665?rik=bgJ%2fRuo147O8lw&riu=http%3a%2f%2flogos-download.com%2fwp-content%2fuploads%2f2016%2f05%2fHoliday_Inn_logo_logotype.png&ehk=BHFCBhYODgfBH4fvZpd3r3c24ay7qBbzcSY692MW3f4%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [2, 3, 7, 1],
        pixelation_level: '60',
    },
    '114': {
        content: 'Pinterest is an American social media service for publishing and discovery of information in the form of pinboards. This includes recipes, home, style, motivation, and inspiration on the Internet using image sharing. Pinterest, Inc. was founded by Ben Silbermann, Paul Sciarra, and Evan Sharp, and is headquartered in San Francisco.',
        submitted_by: 'https://www.pinterest.com/',
        answers: ["Pinterest"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/09/Pinterest-Logo-2011-present.png',
        uncover_order: [8, 2, 6, 1],
        pixelation_level: '45',
    },
    '115': {
        content: '7 Up or Seven Up is an American brand of lemon-lime-flavored non-caffeinated soft drink. The brand and formula are owned by Keurig Dr Pepper, although the beverage is internationally distributed by PepsiCo',
        submitted_by: 'https://www.7up.com/',
        answers: ["7Up"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logohistory.net/wp-content/uploads/2023/08/7Up-Logo.png',
        uncover_order: [8, 7, 0, 3],
        pixelation_level: '30',
    },
    '116': {
        content: 'United Parcel Service, Inc. (UPS) is an American multinational shipping & receiving and supply chain management company founded in 1907. Originally known as the American Messenger Company specializing in telegraphs, UPS has expanded to become a Fortune 500 company and one of the worlds largest shipping couriers.',
        submitted_by: 'https://www.ups.com/',
        answers: ["United Parcel Service", "UPS"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.digitalcheck.com/wp-content/uploads/2014/02/United_Parcel_Service_logo_2014.svg_.png',
        uncover_order: [6, 1, 7, 0],
        pixelation_level: '30',
    },
    '117': {
        content: 'Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography, street maps, 360° interactive panoramic views of streets (Street View), real-time traffic conditions, and route planning for traveling by foot, car, bike, air (in beta) and public transportation.',
        submitted_by: 'https://www.google.com/maps/',
        answers: ["Google Maps", "Maps"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.pngmart.com/files/23/Google-Maps-Logo-PNG-Photos.png',
        uncover_order: [8, 7, 2, 1],
        pixelation_level: '30',
    },
    '118': {
        content: 'The New York Knickerbockers, shortened and more commonly referred to as the New York Knicks, are an American professional basketball team based in the New York City borough of Manhattan. The Knicks compete in the National Basketball Association as a member of the Atlantic Division of the Eastern Conference.',
        submitted_by: 'https://www.nba.com/knicks/',
        answers: ["New York Knicks", "Knicks"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://s3.amazonaws.com/freebiesupply/large/2x/new-york-knicks-logo-transparent.png',
        uncover_order: [4, 6, 8, 7],
        pixelation_level: '30',
    },
    '119': {
        content: 'ExxonMobil Corporation is an American multinational oil and gas corporation and the largest direct descendant of John D. Rockefellers Standard Oil.',
        submitted_by: 'https://www.mobil.com/',
        answers: ["Mobil"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.fc2c2c644292937745ca914659c96907?rik=ytgMQGTUR5GM3g&riu=http%3a%2f%2fwww.freepnglogos.com%2fuploads%2fmobil-1-logo-png--20.png&ehk=CRsbiIN8JCtr6emqkBF72Y30oeBorAYrs9quqX5cpyI%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [5, 0, 1, 8],
        pixelation_level: '30',
    },
    '120': {
        content: 'Airtable is a cloud collaboration service headquartered in San Francisco. It was founded in 2012 by Howie Liu, Andrew Ofstad, and Emmett Nicholas.',
        submitted_by: 'https://www.airtable.com/',
        answers: ["Airtable"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/airtable-4f8ceabd.png?t=1701673127',
        uncover_order: [6, 3, 5, 2],
        pixelation_level: '30',
    },
    '121': {
        content: 'K-Swiss, Inc. is an American athletic shoe brand based in Downtown Los Angeles. It was founded in 1966 and is currently owned by Chinese sports equipment manufacturing company Xtep.',
        submitted_by: 'https://kswiss.com/',
        answers: ["K Swiss"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://i1.wp.com/smartcolaw.com/wp-content/uploads/2017/10/k-swiss-logo-826x1024.png?ssl=1',
        uncover_order: [8, 6, 4, 2],
        pixelation_level: '30',
    },
    '122': {
        content: 'Jägermeister is a German digestif made with 56 herbs and spices. Developed in 1934 by Wilhelm and Curt Mast, it has an alcohol by volume of 35%. The recipe has not changed since its creation and continues to be served in its signature green glass bottle.',
        submitted_by: 'https://www.jagermeister.com/',
        answers: ["Jagermeister"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.pngkit.com/png/full/329-3294862_jager-png.png',
        uncover_order: [5, 3, 4, 7],
        pixelation_level: '30',
    },
    '123': {
        content: 'KakaoTalk, commonly referred to as KaTalk in South Korea, is a mobile messaging app for smartphones operated by Kakao Corporation. It was launched on March 18, 2010, and it is available on mobile and desktop platforms. As of August 2022, KakaoTalk had 53 million monthly active users, 47 million inside Korea.',
        submitted_by: 'https://www.kakaocorp.com/',
        answers: ["Kakaotalk"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://freepngdesign.com/content/uploads/images/kakaotalk-logo-8926.png',
        uncover_order: [8, 1, 7, 3],
        pixelation_level: '30',
    },
    '124': {
        content: 'The United States Air Force is the air service branch of the United States Armed Forces, and is one of the eight uniformed services of the United States.',
        submitted_by: 'https://www.airforce.com/',
        answers: ["US Air Force"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://i.pinimg.com/originals/f1/00/f8/f100f894de4ab9eb9c940d001c0ac4ea.jpg',
        uncover_order: [6, 0, 4, 2],
        pixelation_level: '30',
    },
    '125': {
        content: 'Camel is an American brand of cigarettes, currently owned and manufactured by the R. J. Reynolds Tobacco Company in the United States and by Japan Tobacco outside the U.S. Most recently Camel cigarettes contain a blend of Turkish tobacco and Virginia tobacco.',
        submitted_by: 'https://www.camel.com/',
        answers: ["Camel Cigarettes"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2022/06/Camel-Symbol-700x394.png',
        uncover_order: [6, 8, 7, 3],
        pixelation_level: '30',
    },
    '126': {
        content: 'Tamiya Incorporated is a Japanese manufacturer of plastic model kits, radio-controlled cars, battery and solar powered educational models, sailboat models, acrylic and enamel model paints, and various modeling tools and supplies. The company was founded by Yoshio Tamiya in Shizuoka, Japan, in 1946.',
        submitted_by: 'https://www.tamiyausa.com/',
        answers: ["Tamiya Corporation"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.6942a15bbd4e2e0c8ecbccb297467090?rik=s6I3KmMTl0u%2bsQ&riu=http%3a%2f%2fplastiq.pl%2fimg%2fproduct_info%2fLogo_Tamiya.png&ehk=yRNfar09ZLrRUQlryuUQciv%2fKdwiihNJrqE1FflWDPw%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [5, 2, 1, 3],
        pixelation_level: '30',
    },
    '127': {
        content: 'UnionPay, also known as China UnionPay or by its abbreviation, CUP or UPI internationally, is a Chinese state-owned financial services corporation headquartered in Shanghai, China. It provides bank card services and a major card scheme in mainland China.',
        submitted_by: 'https://www.unionpayintl.com/',
        answers: ["Union Pay"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/logos/large/2x/union-pay-logo-png-transparent.png',
        uncover_order: [6, 8, 0, 7],
        pixelation_level: '30',
    },
    '128': {
        content: 'The Home Depot, Inc. is an American multinational home improvement retail corporation that sells tools, construction products, appliances, and services, including fuel and transportation rentals. Home Depot is the largest home improvement retailer in the United States.',
        submitted_by: 'https://www.homedepot.com/',
        answers: ["The Home Depot"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://cdn.freebiesupply.com/images/large/2x/home-depot-logo-transparent.png',
        uncover_order: [8, 0, 2, 4],
        pixelation_level: '30',
    },
    '129': {
        content: 'HSBC Holdings plc is a British universal bank and financial services group headquartered in London, England, with historical and business links to East Asia and a multinational footprint. It is the largest Europe-based bank by total assets, ahead of BNP Paribas, with US$2.919 trillion as of December 2023.',
        submitted_by: 'https://www.us.hsbc.com/',
        answers: ["HSBC"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/HSBC-9c9da987.png?t=1720244492',
        uncover_order: [5, 3, 7, 1],
        pixelation_level: '55',
    },
    '130': {
        content: 'Fujitsu Limited is a Japanese multinational information and communications technology equipment and services corporation, established in 1935 and headquartered in Kawasaki, Kanagawa. It is the worlds sixth-largest IT services provider by annual revenue, and the largest in Japan, in 2021.',
        submitted_by: 'https://www.fujitsu.com/',
        answers: ["Fujitsu"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/6702.T-316e1889.png?t=1720244490',
        uncover_order: [8, 4, 3, 2],
        pixelation_level: '30',
    },
    '131': {
        content: 'The Milwaukee Bucks are an American professional basketball team in Milwaukee. The Bucks compete in the National Basketball Association as a member of the Central Division of the Eastern Conference. The team was founded in 1968 as an expansion team, and play at Fiserv Forum.',
        submitted_by: 'https://www.nba.com/bucks/',
        answers: ["Milwaukee Bucks"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/M/Milwaukee-Bucks-logo-01.png',
        uncover_order: [0, 2, 4, 8],
        pixelation_level: '30',
    },
    '132': {
        content: 'Messenger, also known as Facebook Messenger, is an American proprietary instant messaging app and platform developed by Meta Platforms.',
        submitted_by: 'https://www.messenger.com/',
        answers: ["Facebook messenger"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/F/Facebook-messenger-logo-01.png',
        uncover_order: [0, 7, 6, 3],
        pixelation_level: '30',
    },
    '133': {
        content: 'Merck & Co., Inc. is an American multinational pharmaceutical company headquartered in Rahway, New Jersey, and is named for Merck Group, founded in Germany in 1668, of which it was once the American arm. The company does business as Merck Sharp & Dohme or MSD outside the United States and Canada.',
        submitted_by: 'https://www.merck.com/',
        answers: ["Merck"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MRK-7f8ec2c5.png?t=1722952496',
        uncover_order: [0, 6, 2, 4],
        pixelation_level: '30',
    },
    '134': {
        content: 'MySQL is an open-source relational database management system. Its name is a combination of My, the name of co-founder Michael Widenius s daughter My, and SQL, the acronym for Structured Query Language.',
        submitted_by: 'https://www.mysql.com/',
        answers: ["MySQL"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/M/MySQL-logo-01.png',
        uncover_order: [8, 1, 2, 3],
        pixelation_level: '15',
    },
    '135': {
        content: 'Groupe Renault is a French multinational automobile manufacturer established in 1899. The company produces a range of cars and vans and in the past, has manufactured trucks, tractors, tanks, buses/coaches, aircraft and aircraft engines, and autorail vehicles.',
        submitted_by: 'https://www.renaultgroup.com/',
        answers: ["Renault"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/R/Renault-logo-01.png',
        uncover_order: [3, 1, 5, 8],
        pixelation_level: '30',
    },
    '136': {
        content: 'Cisco Systems, Inc. is an American multinational digital communications technology conglomerate corporation headquartered in San Jose, California. Cisco develops, manufactures, and sells networking hardware, software, telecommunications equipment and other high-technology services and products.',
        submitted_by: 'https://www.cisco.com/',
        answers: ["Cisco"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/CSCO-187e9f61.png?t=1728111511',
        uncover_order: [1, 2, 0, 5],
        pixelation_level: '40',
    },
    '137': {
        content: 'Koninklijke Philips N.V., commonly shortened to Philips, is a Dutch multinational conglomerate corporation that was founded in Eindhoven in 1891. Since 1997, its world headquarters have been situated in Amsterdam, though the Benelux headquarters is still in Eindhoven.',
        submitted_by: 'https://www.usa.philips.com/',
        answers: ["Philips"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PHG-ee0aced4.png?t=1720244493',
        uncover_order: [6, 8, 7, 5],
        pixelation_level: '30',
    },
    '138': {
        content: 'UNICEF, originally the United Nations International Children s Emergency Fund, officially United Nations Children s Fund since 1953, is an agency of the United Nations responsible for providing humanitarian and developmental aid to children worldwide.',
        submitted_by: 'https://www.unicef.org/',
        answers: ["Unicef", "Unite for Children"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/10/UNICEF-Logo.png',
        uncover_order: [6, 8, 4, 0],
        pixelation_level: '30',
    },
    '139': {
        content: 'Marvel Comics is a New York City-based comic book publisher, a property of The Walt Disney Company since December 31, 2009, and a subsidiary of Disney Publishing Worldwide since March 2023. Marvel was founded in 1939 by Martin Goodman as Timely Comics, and by 1951 had generally become known as Atlas Comics.',
        submitted_by: 'https://www.marvel.com/',
        answers: ["Marvel Comics", "Marvel"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/11/Marvel-Logo-2000-2012.png',
        uncover_order: [0, 2, 8, 7],
        pixelation_level: '30',
    },
    '140': {
        content: 'Cisco Jabber delivers instant messaging, voice and video calls, voice messaging, desktop sharing, conferencing, and presence.',
        submitted_by: 'https://www.cisco.com/c/en/us/products/unified-communications/jabber/',
        answers: ["Jabber"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/J/Jabber-logo-01.png',
        uncover_order: [4, 5, 7, 8],
        pixelation_level: '23',
    },
    '141': {
        content: 'Lyft, Inc. is an American company offering mobility as a service, ride-hailing, vehicles for hire, motorized scooters, a bicycle-sharing system, rental cars, and food delivery in the United States and select cities in Canada.',
        submitted_by: 'https://www.lyft.com/',
        answers: ["Lyft"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/L/Lyft-logo-01.png',
        uncover_order: [0, 6, 2, 8],
        pixelation_level: '30',
    },
    '142': {
        content: 'PPG Industries, Inc. is an American Fortune 500 company and global supplier of paints, coatings, and specialty materials. With headquarters in Pittsburgh, Pennsylvania, PPG operates in more than 70 countries around the globe. By revenue it is the largest coatings company in the world followed by Sherwin-Williams.',
        submitted_by: 'https://www.ppg.com/',
        answers: ["PPG"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/P/PPG-logo-01.png',
        uncover_order: [0, 6, 2, 8],
        pixelation_level: '20',
    },
    '143': {
        content: 'Google LLC is an American multinational technology company focusing on artificial intelligence, online advertising, search engine technology, cloud computing, computer software, quantum computing, e-commerce, and consumer electronics. The logo vector represents the brand color and trademark rights.',
        submitted_by: 'https://about.google/',
        answers: ["Google"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/G/Google-2015-Favicon-logo-01.png',
        uncover_order: [0, 2, 8, 6],
        pixelation_level: '30',
    },
    '144': {
        content: 'Mickey Mouse Clubhouse is an American animated interactive television series for preschoolers. Produced by Disney Television Animation, the series was created by Disney veteran Bobs Gannaway.',
        submitted_by: 'https://www.imdb.com/title/tt0784896/',
        answers: ["Mickey Mouse Clubhouse"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/M/Mickey-Mouse-Clubhouse-logo-01.png',
        uncover_order: [0, 1, 2, 8],
        pixelation_level: '30',
    },
    '145': {
        content: 'Kia Corporation is a South Korean multinational automobile manufacturer headquartered in Seoul, South Korea. It is South Koreas second largest automobile manufacturer, after its parent company, Hyundai Motor Company, with sales of over 2.8 million vehicles in 2019.',
        submitted_by: 'https://www.kia.com/',
        answers: ["KIA"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/K/KIA-logo-01.png',
        uncover_order: [0, 2, 6, 8],
        pixelation_level: '30',
    },
    '146': {
        content: 'Snapchat is an American multimedia instant messaging app and service developed by Snap Inc., originally Snapchat Inc. One of the principal features of Snapchat is that pictures and messages are usually only available for a short time before they become inaccessible to their recipients.',
        submitted_by: 'https://www.snapchat.com/',
        answers: ["Snapchat"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/S/Snap-Ghost-logo-01.png',
        uncover_order: [0, 2, 6, 8],
        pixelation_level: '30',
    },
    '147': {
        content: 'Lacoste S.A. is a French company, founded in 1933 by tennis player René Lacoste, and entrepreneur André Gillier. It sells clothing, footwear, sportswear, eyewear, leather goods, perfume, towels and watches. The company can be recognised by its green Crocodile logo.',
        submitted_by: 'https://global.lacoste.com/',
        answers: ["Lacoste"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/09/Lacoste-Symbol.png',
        uncover_order: [2, 8, 0, 1],
        pixelation_level: '30',
    },
    '148': {
        content: 'The Colgate-Palmolive Company, commonly known as Colgate-Palmolive, is an American multinational consumer products company headquartered on Park Avenue in Midtown Manhattan, New York City.',
        submitted_by: 'https://www.colgate.com/',
        answers: ["Colgate"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://logos-world.net/wp-content/uploads/2020/09/Colgate-Emblem.png',
        uncover_order: [8, 6, 7, 0],
        pixelation_level: '30',
    },
    '149': {
        content: 'The Hyundai Motor Company, often referred to as Hyundai Motors, and commonly known as Hyundai, is a South Korean multinational automotive manufacturer headquartered in Seoul, South Korea, which was founded in 1967.',
        submitted_by: 'https://www.hyundaiusa.com/us/en',
        answers: ["Hyundai"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://freepnglogo.com/images/all_img/1720112736hyundai-car-logo-png-image.png',
        uncover_order: [5, 3, 4, 2],
        pixelation_level: '30',
    },
    '150': {
        content: 'Peugeot is a French brand of automobiles owned by Stellantis. The family business that preceded the current Peugeot companies was founded in 1810, and it is regarded as the oldest car company in the world. On 20 November 1858, Émile Peugeot applied for the lion trademark.',
        submitted_by: 'https://www.peugeot.com/',
        answers: ["Peugeot"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://th.bing.com/th/id/R.637cfd3724d8e5bc3ec9b2550a723d87?rik=HhaOkU%2fGNdIl9A&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fpeugeot-logo-eps-png-peugeot-logo-hd-png-meaning-information-2200.png&ehk=awBTo%2bY%2fxBySw2J8d%2bwI2crKXJQTLRSgqGM8RqaEiv4%3d&risl=&pid=ImgRaw&r=0',
        uncover_order: [7, 8, 2, 5],
        pixelation_level: '30',
    },
    '151': {
        content: 'Pepsi is a carbonated soft drink with a cola flavor, manufactured by PepsiCo. It was originally created and developed in 1893 by Caleb Bradham in the United States, and became known as Pepsi-Cola in 1898, before shortening to Pepsi in 1961.',
        submitted_by: 'https://www.pepsi.com/',
        answers: ["Pepsi"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://www.freepnglogos.com/uploads/pepsi-png-logo/pepsi-logo-pepsi-symbol-meaning-history-png-logo-3.png',
        uncover_order: [4, 0, 2, 8],
        pixelation_level: '30',
    },
    '152': {
        content: 'White Castle is an American regional hamburger restaurant chain with about 345 locations across 13 states, with its greatest presence in the Midwest and New York metropolitan area.',
        submitted_by: 'https://www.whitecastle.com/',
        answers: ["White Castle"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/W/White-Castle-logo-01.png',
        uncover_order: [0, 2, 1, 6],
        pixelation_level: '30',
    },
    '153': {
        content: 'JavaScript, often abbreviated as JS, is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS.',
        submitted_by: 'https://www.javascript.com/',
        answers: ["JavaScript"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/J/JavaScript-logo-01.png',
        uncover_order: [1, 0, 2, 3],
        pixelation_level: '30',
    },
    '154': {
        content: 'EA Sports is a division of Electronic Arts that develops and publishes sports video games. Formerly a marketing gimmick of Electronic Arts, in which they imitated real-life sports networks by calling themselves the \"EA Sports Network\" (EASN) with pictures or endorsements, it soon grew up to become a sub-label on its own, releasing game series such as EA Sports FC, PGA Tour, NHL, NBA Live, and Madden NFL.',
        submitted_by: 'https://www.ea.com/sports',
        answers: ["EA Sports"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/E/EA-Sports-logo-01.png',
        uncover_order: [2, 0, 1, 5],
        pixelation_level: '30',
    },
    '155': {
        content: 'Nikon Corporation is a Japanese optics and photographic equipment manufacturer. Nikons products include cameras, camera lenses, binoculars, microscopes, ophthalmic lenses, measurement instruments, rifle scopes, spotting scopes, and equipment related to semiconductor fabrication, such as steppers used in the photolithography steps of such manufacturing. Nikon is the worlds second largest manufacturer of such equipment.',
        submitted_by: 'https://www.nikonusa.com/',
        answers: ["Nikon"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/N/Nikon-logo-01.png',
        uncover_order: [0, 2, 1, 5],
        pixelation_level: '30',
    },
    '156': {
        content: 'The United States Department of Homeland Security is the U.S. federal executive department responsible for public security, roughly comparable to the interior or home ministries of other countries.',
        submitted_by: 'https://www.dhs.gov/',
        answers: ["US Department of Homeland Security"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/U/US-Department-of-Homeland-Security-logo-01.png',
        uncover_order: [8, 6, 0, 2],
        pixelation_level: '30',
    },
    '157': {
        content: 'The Indiana Pacers are an American professional basketball team based in Indianapolis. The Pacers compete in the National Basketball Association as a member of the Central Division of the Eastern Conference.',
        submitted_by: 'https://www.nba.com/pacers/',
        answers: ["Indiana Pacers"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://brandeps.com/logo-download/I/Indiana-Pacers-logo-01.png',
        uncover_order: [0, 8, 6, 2],
        pixelation_level: '30',
    },
    '158': {
        content: 'Avast Antivirus is a family of cross-platform internet security applications developed by Avast for Microsoft Windows, macOS, Android, and iOS.',
        submitted_by: 'https://www.avast.com/',
        answers: ["Avast"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/AV2.F-439b0303.png?t=1720244490',
        uncover_order: [4, 2, 0, 8],
        pixelation_level: '30',
    },
};
